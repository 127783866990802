<script>
import Job from './job.vue'

export default {
  props: {
  },
  components: {
    Job
  },
  methods: {
    setAlwaysShowActions (e) {
      localStorage.setItem('alwaysShowInScreen', !e.target.checked)
      this.$store.commit('asyncJobs/setAlwaysShowInScreen', !e.target.checked)
    }
  },
  computed: {
    allJobs () {
      return this.$store.getters['asyncJobs/jobs']
    },
    showJobs () {
      return this.$store.getters['asyncJobs/showJobs']
    },
    alwaysShowActions () {
      return this.$store.getters['asyncJobs/alwaysShowInScreen']
    }
  },
};
</script>

<template>
  <div v-if="showJobs.length && alwaysShowActions" class="position-fixed p-2  click-through" style="top:120px; right:24px; z-index:10;width:100%; max-width:380px;max-height:80vh;">
    <div class="mx-3 rounded-top px-2 bg-light border py-1">
      <div class="form-check mb-0   ">
        <input class="form-check-input not-opacity clickable" type="checkbox" id="dontShowAnymore" :checked="!alwaysShowActions" @change="setAlwaysShowActions">
        <label class="form-check-label fs-11 fw-medium text-primary not-opacity" for="dontShowAnymore">
          <p class="text-primary not-opacity">Não exibir novamente</p> 
        </label>
      </div>
    </div>
    <div class="card container h-100" style="overflow-y:scroll;">
      <div v-for="job in showJobs" :key="job.id" class="text-muted p-2 border rounded-1 mb-2">
        <Job :job="job" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.container::-webkit-scrollbar {
    display: none;
}

.container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.click-through {
  pointer-events: none; /* Permite cliques através deste elemento */
  opacity: 0.8; /* Define a opacidade do elemento */
}

.clickable {
  pointer-events: auto; /* Elementos internos que ainda podem ser clicados */
}

.not-opacity{
  opacity: 1;
}

</style>